import { DMY_CASUAL_FORMAT, SHORT_MONTH_LONG_YEAR } from 'constants/dateFormats'
import moment from 'moment'

export const parseSelectedTravelDatesToFormText = (filters: App.OfferListFilters): string => {
  if (!filters) {
    return 'Anytime'
  }

  const { flexibleMonths, flexibleNights, checkIn, checkOut } = filters
  let result = 'Anytime'

  if (flexibleMonths) {
    const flexibleMonthsArr = flexibleMonths.split(',')
    const formattedMonths = flexibleMonthsArr
      .map(month => moment(month).format(SHORT_MONTH_LONG_YEAR))
      .join(' or ')

    if (flexibleNights) {
      result = `${flexibleNights} nights, ${formattedMonths}`
    } else {
      result = formattedMonths
    }
  } else if (checkIn && checkOut) {
    result = `${moment(checkIn).format(DMY_CASUAL_FORMAT)} - ${moment(checkOut).format(DMY_CASUAL_FORMAT)}`
  }

  return result
}

export const parseSelectedNumberOfTravellersToFormText = (filters: App.OfferListFilters) => {
  if (!filters) {
    return ''
  }
  const result = filters?.rooms?.reduce(
    (totals, room) => ({
      totalAdults: totals.totalAdults + (room.adults || 0),
      totalChildren: totals.totalChildren + (room.children || 0),
    }),
    { totalAdults: 0, totalChildren: 0 },
  ) || { totalAdults: 0, totalChildren: 0 }

  const adultText = result.totalAdults > 0 ?
      (result.totalAdults === 1 ? '1 Adult' : `${result.totalAdults} Adults`) :
    ''
  const childText = result.totalChildren > 0 ?
      (result.totalChildren === 1 ? '1 Child' : `${result.totalChildren} Children`) :
    ''

  return [adultText, childText].filter(Boolean).join(', ')
}
