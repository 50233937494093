import DatePickerInput from 'components/Common/Form/Input/DatePickerInput'
import InputLabelWrap from 'components/Common/Form/Input/InputLabelWrap'
import Select from 'components/Common/Form/Input/Select'
import Group from 'components/utils/Group'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import moment from 'moment'
import React, { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react'

export const INTERVALS = [
  { key: 'As soon as possible', start: 'As soon as possible' },
  { key: '09:00 to 09:30', start: '09:00' },
  { key: '09:30 to 10:00', start: '09:30' },
  { key: '10:00 to 10:30', start: '10:00' },
  { key: '10:30 to 11:00', start: '10:30' },
  { key: '11:00 to 11:30', start: '11:00' },
  { key: '11:30 to 12:00', start: '11:30' },
  { key: '12:00 to 12:30', start: '12:00' },
  { key: '12:30 to 13:00', start: '12:30' },
  { key: '13:00 to 13:30', start: '13:00' },
  { key: '13:30 to 14:00', start: '13:30' },
  { key: '14:00 to 14:30', start: '14:00' },
  { key: '14:30 to 15:00', start: '14:30' },
  { key: '15:00 to 15:30', start: '15:00' },
  { key: '15:30 to 16:00', start: '15:30' },
  { key: '16:00 to 16:30', start: '16:00' },
  { key: '16:30 to 17:00', start: '16:30' },
  { key: '17:00 to 17:30', start: '17:00' },
  { key: '17:30 to 18:00', start: '17:30' },
  { key: '18:00 to 18:30', start: '18:00' },
]

const TIME_ZONES = [
  'Australia/Sydney',
  'Australia/Melbourne',
  'Australia/Brisbane',
  'Australia/Hobart',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Perth',
  'Pacific/Auckland',
]

interface Interval {
  key: string;
  start: string;
}

const NOW = moment()

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

function AgentCallbackRequestDateTimeInput() {
  const [date, setDate] = useState<string | undefined >(NOW.format(ISO_DATE_FORMAT))

  const filteredIntervals = useMemo<Array<Interval>>(() => {
    const currentHours = NOW.hours()
    const currentMinutes = NOW.minutes()

    if (date === NOW.format(ISO_DATE_FORMAT) && NOW.hour() >= 9) {
      const filtered = INTERVALS.filter(interval => {
        if (interval.key === 'As soon as possible') {
          return true
        }

        const [hours, minutes] = interval.start.split(':').map(Number)
        return hours > currentHours || (hours === currentHours && minutes > currentMinutes)
      })

      return filtered
    }
    else {
      const filtered = INTERVALS.filter(interval => {
        if (interval.key === 'As soon as possible') {
          return false
        }
        return true
      })

      return filtered
    }
  }, [date])

  const [time, setTime] = useState<string>(filteredIntervals[0].start)
  const [timezone, setTimezone] = useState<string>(tz)

  const handleDateChange = useCallback<NonNullable<ComponentProps<typeof DatePickerInput>['onDateChange']>>((_, isoDate) => {
    setDate(isoDate)
  }, [])

  useEffect(() => {
    // If passed 6pm then make nearest available callback date to be tomorrow
    if (NOW.hour() >= 18) {
      const tomorrowsDate = moment(NOW).add(1, 'day')
      setDate(tomorrowsDate.format(ISO_DATE_FORMAT))
    }

    setTime(filteredIntervals[0].start)
  }, [filteredIntervals])

  const minDate = useMemo(() => {
    if (NOW.hour() >= 18) {
      // Get just the date for the date picker modal
      const minCallbackDate = new Date(NOW.year(), NOW.month(), NOW.date() + 1)
      return minCallbackDate
    }
    else {
      const minCallbackDate = new Date(NOW.year(), NOW.month(), NOW.date())
      return minCallbackDate
    }
  }, [])

  return <Group direction="vertical" gap={8} horizontalAlign="stretch">
    <InputLabelWrap
        label="Preferred callback date and time"
        optionalIndicatorEnabled={false}
      >
      <Group direction="horizontal" gap={8} horizontalAlign="stretch">
        <DatePickerInput
          required
          dropdownPlacement="bottom"
          name="callbackDate"
          min={minDate}
          value={date}
          onDateChange={handleDateChange}
          requiredErrorMessage="Please select date"
        />
        <Select
          required
          invalidErrorMessage="Please select time"
          name="callbackTime"
          autoComplete="off"
          value={time}
          onChange={(e) => setTime(e.target.value)}
          >
          {filteredIntervals.map((interval) => (
            <option key={interval.key} value={interval.start}>
              {interval.key}
            </option>
          ))}
        </Select>
      </Group>
      <Select
        required
        label="Timezone"
        invalidErrorMessage="Please select timezone"
        name="timezone"
        autoComplete="off"
        value={timezone}
        onChange={(e) => setTimezone(e.target.value)}
      >
        {TIME_ZONES.map((timezone) => (
          <option key={timezone} value={timezone}>
            {timezone}
          </option>
        ))}
      </Select>
    </InputLabelWrap>
  </Group>
}

export default AgentCallbackRequestDateTimeInput
